import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationHandler } from '@epcbuilder/lib/components/Layout';
import { ReferrerCookieHandler } from '@epcbuilder/lib/components/ReferrerCookieHandler';
import FAQ from '@/app/faq';
import FinanaceFunding from '@/app/financefunding';
import HomeImprovementsPage from '@/app/homeimprovementspage';
import InstallMeasures from '@/app/installmeasures';
import LandingPage from '@/app/landing';
import Privacy from '@/app/privacy';
import QualityAssurance from '@/app/qualityassurance';
import QuickWins from '@/app/quickwins';
import Terms from '@/app/terms';
import WhatToExpect from '@/app/whattoexpect';
import NotFound from './app/404';
import ContactUs from './app/contactus';
import DirectDebitGuarantee from './app/directdebitguarantee';
import Refund from './app/refund';
import YourSavings from './app/yoursavings';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ReferrerCookieHandler>
        <NavigationHandler>
          <Routes>
            <Route path="/">
              <Route index element={<LandingPage />} />
              <Route path="faq" element={<FAQ />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="terms" element={<Terms />} />
              <Route path="refund" element={<Refund />} />
              <Route path="direct-debit-guarantee" element={<DirectDebitGuarantee />} />
              <Route path="what-to-expect" element={<WhatToExpect />} />
              <Route path="quick-wins" element={<QuickWins />} />
              <Route path="install-measures" element={<InstallMeasures />} />
              <Route path="home-improvements" element={<HomeImprovementsPage />} />
              <Route path="quality-assurance" element={<QualityAssurance />} />
              <Route path="finance-and-funding" element={<FinanaceFunding />} />
              <Route path="your-savings" element={<YourSavings />} />
              <Route path="contactus" element={<ContactUs />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </NavigationHandler>
      </ReferrerCookieHandler>
    </BrowserRouter>
  );
};

export default AppRoutes;
