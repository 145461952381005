import React from 'react';
import { Helmet } from 'react-helmet';
import { ContactUsPage } from '@/components/contact-us';
import Layout from '@/components/Layout';

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder Contact Us">
        <ContactUsPage />
      </Layout>
    </>
  );
};

export default ContactUs;
