import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '@/components/Layout';
import WhatToExpectPage from '@/components/what-to-expect/WhatToExpectPage';

const WhatToExpect = () => {
  return (
    <>
      <Helmet>
        <title>EPC Builder</title>
        <meta
          name="description"
          content="Improving your property's value, efficiency, and running costs with home upgrades"
        />
      </Helmet>
      <Layout title="EPC Builder What to Expect">
        <WhatToExpectPage />
      </Layout>
    </>
  );
};

export default WhatToExpect;
