import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { MdOutlineMail, MdOutlinePhone, MdOutlinePinDrop } from 'react-icons/md';
import { toast } from 'react-toastify';
import { NewSelect, TextAreaInput, TextInput } from '@epcbuilder/lib/components';
import { NewButton } from '@epcbuilder/lib/components/Buttons';
import { EMAIL_REGEX } from '@epcbuilder/lib/utils';
import { handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { PHONE_REGEX } from '@epcbuilder/lib/utils/generic';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ContactUsFormData } from '@/models/contact';
import { postContactUs } from '@/network/contact';
import WavyLines from '../wavy-lines';

const contactUsSchema = yup.object().shape({
  firstName: yup.string().required('First name must not be empty'),
  lastName: yup.string().required('Last name must not be empty'),
  company: yup.string(),
  email: yup.string().matches(EMAIL_REGEX, 'Email is not a valid email address').required('Email must not be empty'),
  phone: yup.string().matches(PHONE_REGEX, 'Phone is not a valid UK phone number').required('Phone must not be empty'),
  ownerStatus: yup.string().required('Owner Status must be entered'),
  message: yup.string().test('message-required-if-other', 'Please provide more details', function (value) {
    const status = this.parent.ownerStatus;
    return status !== 'other' || (status === 'other' && !!value?.trim());
  }),
});

const ContactUsPage = () => {
  const defaultValues: ContactUsFormData = {
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    message: '',
    ownerStatus: '',
  };

  const ownerStatusOptions = [
    { value: 'homeowner', label: 'Homeowner' },
    { value: 'tenant', label: 'Tenant' },
    { value: 'landlord', label: 'Landlord' },
    { value: 'estate_agent', label: 'Estate Agent' },
    { value: 'other', label: 'Other (please specify)' },
  ];

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ContactUsFormData>({
    defaultValues,
    resolver: yupResolver(contactUsSchema),
    reValidateMode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<ContactUsFormData> = async (data) => {
    try {
      await postContactUs(data);
      toast.success('Thank you for your message. A member of our team will be in touch.');
    } catch (error: unknown) {
      handleUnknownDetail(error);
    }
  };

  return (
    <div className="relative flex min-h-screen w-full flex-col items-center overflow-hidden bg-gradient-to-r from-white to-[#C0E2DE]">
      <div className="flex w-full max-w-6xl flex-col gap-8 px-8 py-6 sm:py-12 lg:flex-row">
        <div className="flex w-full flex-col justify-between">
          <div>
            <h2 className="text-dark text-md text-2xl font-bold leading-tight md:text-[48px] xl:text-[64px]">
              Contact us
            </h2>
            <p className="text-large font-sans leading-[30px] md:text-2xl md:leading-[48px]">
              Fill out the form and our team will be in touch.
            </p>
          </div>
          <div className="z-10 hidden flex-col gap-6 md:mt-6 lg:flex">
            <div className="flex w-full flex-col gap-2">
              <h2 className="text-[22px] font-medium">Get in touch.</h2>
              <a href="mailto:info@epcbuilder.com" className="flex flex-row gap-2">
                <MdOutlineMail size={20} />
                <p className="text-sm lg:text-base">info@epcbuilder.com</p>
              </a>
              <a href="tel:08000584140" className="flex flex-row gap-2">
                <MdOutlinePhone size={20} />
                <p className="text-sm lg:text-base">0800 058 4140</p>
              </a>
              <div className="flex flex-row gap-2">
                <MdOutlinePinDrop size={20} />
                <div className="flex flex-col">
                  <address className="text-[16px] not-italic">Station House, Stamford New Road</address>
                  <address className="text-[16px] not-italic">Altrincham</address>
                  <address className="text-[16px] not-italic">WA14 1EP</address>
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col gap-2">
              <h2 className="text-[22px] font-medium">Stay connected.</h2>
              <div className="flex flex-col gap-2">
                <a
                  href="https://www.facebook.com/TheImproveasyGroup/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row gap-2"
                >
                  <div className="size-7 items-center justify-center rounded-md bg-white p-1 lg:size-8 lg:p-2">
                    <FaFacebookF className="text-[#227D72]" />
                  </div>
                  <p>@TheImproveasyGroup</p>
                </a>
                <a
                  href="https://www.linkedin.com/company/improveasy/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row gap-2"
                >
                  <div className="flex size-7 items-center justify-center rounded-md bg-white p-1 lg:size-8 lg:p-2">
                    <FaLinkedinIn className="text-[#227D72]" />
                  </div>
                  <p>@improveasy</p>
                </a>
                <a
                  href="https://twitter.com/ImproveasyGroup"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row gap-2"
                >
                  <div className="flex size-7 items-center justify-center rounded-md bg-white p-1 lg:size-8 lg:p-2">
                    <FaXTwitter className="text-[#227D72]" />
                  </div>
                  <p>@ImproveasyGroup</p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="z-10 flex-col rounded-xl bg-[#F8F9FA] p-6 shadow sm:w-full">
          <form className="flex flex-col gap-6" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 sm:flex-row">
              <TextInput
                {...register('firstName')}
                id="firstName"
                name="firstName"
                title="Your First name"
                label="First Name"
                placeholder="Enter your first name"
                error={errors.firstName?.message}
              />
              <TextInput
                {...register('lastName')}
                id="lastName"
                name="lastName"
                title="Your Last name"
                label="Last Name"
                placeholder="Enter your last name"
                error={errors.lastName?.message}
              />
            </div>
            <TextInput
              {...register('company')}
              id="company"
              name="company"
              title="Company"
              label="Company (Optional)"
              placeholder="Enter your company"
              error={errors.company?.message}
            />
            <div className="flex flex-col gap-4 sm:flex-row">
              <TextInput
                {...register('email')}
                id="email"
                name="email"
                title="Your email"
                label="Email"
                placeholder="Enter your email address"
                error={errors.email?.message}
              />
              <TextInput
                {...register('phone')}
                id="phone"
                name="phone"
                title="Your phone number"
                placeholder="Enter your phone"
                label="Phone Number"
                error={errors.phone?.message}
              />
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold">I am a...</p>
              <NewSelect
                className="rounded-[10px]"
                id="ownerStatus"
                name="ownerStatus"
                control={control}
                title="Select owner status"
                placeholder="Select"
                error={errors.ownerStatus?.message}
                options={ownerStatusOptions}
              />
            </div>
            <TextAreaInput
              {...register('message')}
              id="message"
              name="message"
              title="Your message"
              label="Message"
              placeholder="Enter your message here"
              error={errors.message?.message}
              rows={3}
            />
            <NewButton id="send-message" loading={isSubmitting} type="submit" text="Send Message" />
          </form>
        </div>

        {/* Social Section: Moves BELOW the Form on Small Screens */}
        <div className="z-10 flex flex-col gap-6 lg:hidden">
          <div className="flex w-full flex-col gap-2">
            <h2 className="text-[22px] font-medium">Get in touch.</h2>
            <a href="mailto:info@epcbuilder.com" className="flex flex-row gap-2">
              <MdOutlineMail size={20} />
              <p>info@epcbuilder.com</p>
            </a>
            <a href="tel:08000584140" className="flex flex-row gap-2">
              <MdOutlinePhone size={20} />
              <p className="lg:text-base">0800 058 4140</p>
            </a>
            <div className="flex flex-row gap-2">
              <MdOutlinePinDrop size={20} />
              <div className="flex flex-col">
                <address className="not-italic">Station House, Stamford New Road</address>
                <address className="not-italic">Altrincham</address>
                <address className="not-italic">WA14 1EP</address>
              </div>
            </div>
          </div>
          <div className="flex w-full flex-col gap-2">
            <h2 className="text-[22px] font-medium">Stay connected.</h2>
            <div className="flex flex-col gap-2">
              <a
                href="https://www.facebook.com/TheImproveasyGroup/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row gap-2"
              >
                <div className="size-7 items-center justify-center rounded-md bg-white p-1 lg:size-8 lg:p-2">
                  <FaFacebookF className="text-[#227D72]" />
                </div>
                <p>@TheImproveasyGroup</p>
              </a>
              <a
                href="https://www.linkedin.com/company/improveasy/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row gap-2"
              >
                <div className="flex size-7 items-center justify-center rounded-md bg-white p-1 lg:size-8 lg:p-2">
                  <FaLinkedinIn className="text-[#227D72]" />
                </div>
                <p>@improveasy</p>
              </a>
              <a
                href="https://twitter.com/ImproveasyGroup"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row gap-2"
              >
                <div className="flex size-7 items-center justify-center rounded-md bg-white p-1 lg:size-8 lg:p-2">
                  <FaXTwitter className="text-[#227D72]" />
                </div>
                <p>@ImproveasyGroup</p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full">
        <WavyLines />
      </div>
    </div>
  );
};

export default ContactUsPage;
