import React, { useEffect, useState } from 'react';
import { MdClose, MdMenu } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Button, Image } from '@epcbuilder/lib/components';
import usePreloadImage from '@epcbuilder/lib/hooks/usePreloadImage';

const TopBar = () => {
  usePreloadImage('/images/logo.png');

  const [mounted, setMounted] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
      document.body.style.paddingRight = '5px';

      return () => {
        document.body.style.overflowY = 'scroll';
        document.body.style.paddingRight = '0';
      };
    }
  }, [open]);

  useEffect(() => {
    if (mounted) {
      const handleMediaChange = (event: MediaQueryListEvent) => {
        setIsMobile(event.matches);
      };

      const mediaQueryList = window.matchMedia('(max-width: 767px)');
      mediaQueryList.addEventListener('change', handleMediaChange);

      setIsMobile(mediaQueryList.matches);
      return () => {
        mediaQueryList.removeEventListener('change', handleMediaChange);
      };
    }
  }, [mounted]);

  if (!mounted) return <></>;

  return (
    <header
      className={`${
        open ? 'fixed relative h-screen md:absolute md:h-24' : 'absolute h-24'
      } lg:bg-light left-0 top-0 z-10 w-full`}
    >
      <div className="flex h-24 w-full flex-row justify-between gap-4 px-4 shadow-md">
        <div
          className={`${
            open && isMobile && 'hidden'
          } relative h-20 w-36 md:left-0 md:top-0 md:h-auto md:w-48 md:translate-x-0`}
        >
          <a href="/">
            <Image src="/images/logo.png" alt="EPC Builder Logo" className="size-full object-contain" />
          </a>
        </div>

        <div className="hidden flex-row gap-4 md:flex lg:gap-8">
          <div className="text-dark flex flex-row items-center gap-4 text-sm lg:gap-8 lg:text-base">
            <Link className="font-header font-extrabold uppercase" to="/" aria-label="Navigate to home page">
              Home
            </Link>
            <Link className="font-header font-extrabold uppercase" to="/faq" aria-label="Navigate to FAQ page">
              FAQ
            </Link>
            <Link to="/contactus" className="font-header font-extrabold uppercase" aria-label="Open contact form">
              Contact
            </Link>
          </div>
          <div className="flex flex-row items-center gap-4">
            <a href={`${import.meta.env.VITE_WEB_URL}/register`}>
              <Button
                className="font-header flex h-8 w-full min-w-fit items-center justify-center rounded-[12px] px-4 text-sm uppercase shadow hover:opacity-80 lg:h-10 lg:rounded-[16px] lg:text-base"
                overrideBaseClassnames={true}
                id="register-button"
              >
                Register
              </Button>
            </a>
            <a href={import.meta.env.VITE_WEB_URL}>
              <Button
                className="font-header flex h-8 w-full min-w-fit items-center justify-center rounded-[12px] px-4 text-sm uppercase shadow hover:opacity-80 lg:h-10 lg:rounded-[16px] lg:text-base"
                overrideBaseClassnames={true}
                style="secondary"
              >
                Log in
              </Button>
            </a>
          </div>
        </div>
        <div className="flex items-center md:hidden">
          <div className="text-primary flex size-8 cursor-pointer items-center justify-center">
            <MdMenu size={32} onClick={() => setOpen(true)} />
          </div>
        </div>
      </div>
      {open && (
        <div className="bg-light absolute left-0 top-0 z-50 flex size-full flex-col items-center justify-between gap-16 px-8 pb-16 pt-40 md:hidden">
          <div className="text-primary absolute right-4 top-8 cursor-pointer">
            <MdClose size={32} onClick={() => setOpen(false)} />
          </div>
          <div className="text-dark flex flex-col items-center gap-8 text-base">
            <Link className="font-header text-xl font-extrabold" to="/" aria-label="Navigate to home page">
              Home
            </Link>
            <Link className="font-header text-xl font-extrabold" to="/faq" aria-label="Navigate to FAQ page">
              FAQ
            </Link>
            <Link to="/contactus" className="font-header text-xl font-extrabold" aria-label="Open contact form">
              Contact
            </Link>
          </div>
          <div className="flex max-w-xs flex-col gap-4">
            <h1 className="font-header text-3xl font-black">Ready to get started?</h1>
            <p className="font-header text-xs font-bold uppercase italic">
              Why waste time searching for various installers, assessors, and finance solutions? We have it all under
              one roof.
            </p>
            <a href={`${import.meta.env.VITE_WEB_URL}/register`}>
              <Button
                className="font-header flex h-10 w-full min-w-fit items-center justify-center rounded-[18px] px-4 text-sm uppercase shadow hover:opacity-80 lg:h-10 lg:rounded-[16px] lg:text-base"
                overrideBaseClassnames={true}
              >
                Register
              </Button>
            </a>
            <a href={import.meta.env.VITE_WEB_URL}>
              <Button
                className="font-header flex h-10 w-full min-w-fit items-center justify-center rounded-[18px] px-4 text-sm uppercase shadow hover:opacity-80 lg:h-10 lg:rounded-[16px] lg:text-base"
                overrideBaseClassnames={true}
                style="secondary"
              >
                Log in
              </Button>
            </a>
          </div>
        </div>
      )}
    </header>
  );
};

export default TopBar;
