import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Loading, TextInput } from '@epcbuilder/lib/components';
import { Address, AddressResult, PostcodeSearch } from '@epcbuilder/lib/models/properties';
import { getAddressesForPostcode, getAddressRetrieve } from '@epcbuilder/lib/network/properties';
import { handleFormErrors, POSTCODE_REGEX } from '@epcbuilder/lib/utils';
import { AxiosErrorData, handleUnknownDetail } from '@epcbuilder/lib/utils/api';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormStage } from '@/models/generic';

const postcodeSearchSchema = yup.object().shape({
  postcode: yup
    .string()
    .required('Postcode must not be empty')
    .matches(POSTCODE_REGEX, 'Please enter a valid postcode'),
});

const PostcodeStage = ({
  postcode,
  setPostcode,
  initialResults,
  handleAddressFromPostcode,
  setFormStage,
}: {
  postcode: string;
  setPostcode: Dispatch<SetStateAction<string>>;
  initialResults: AddressResult[];
  handleAddressFromPostcode: (result: Address) => void;
  setFormStage: Dispatch<SetStateAction<FormStage>>;
}) => {
  const [results, setResults] = useState<AddressResult[]>(initialResults);
  const [addressId, setAddressId] = useState<string>('');

  const defaultValues: PostcodeSearch = {
    postcode: postcode || '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<PostcodeSearch>({
    defaultValues,
    resolver: yupResolver(postcodeSearchSchema),
  });

  const onSubmit: SubmitHandler<PostcodeSearch> = useCallback(
    async (data: PostcodeSearch) => {
      try {
        const response = await getAddressesForPostcode({ postcode: data.postcode.trim() });
        setPostcode(data.postcode.trim());
        setResults(response);
        setAddressId('');
      } catch (error: unknown) {
        const { errors, detail } = error as AxiosErrorData;
        handleFormErrors<PostcodeSearch>(setError, errors);

        switch (detail) {
          case 'No addresses could be found':
          case 'Failed getting address':
            toast.error('There was an error, please check your postcode and try again');
            break;
          default:
            handleUnknownDetail(error);
            break;
        }
      }
    },
    [setError, setPostcode]
  );

  const processSelectedAddress = useCallback(async () => {
    const address = await getAddressRetrieve({ id: addressId });
    handleAddressFromPostcode(address[0]);
  }, [addressId, handleAddressFromPostcode]);

  if (isSubmitting) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <h1 id="step-1-heading" className="text-2xl">
          Step 1: Select Your Address
        </h1>
        <p>Find your property to begin your EPC Builder journey.</p>
      </div>

      <div className="mt-4 flex flex-col gap-4">
        <form className="flex flex-col gap-1" onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            {...register('postcode')}
            id="Postcode"
            name="postcode"
            title="Your postcode"
            placeholder="Postcode"
            error={errors.postcode?.message}
          />
          {results.length === 0 && (
            <p role="alert" className="text-error text-center text-sm">
              No addresses were found. Please click below to enter your address manually.
            </p>
          )}
          {results.length > 0 && (
            <div className="border-blue bg-light overflow-y-hidden rounded-xl border-2 shadow">
              <div className="no-scrollbar flex h-48 flex-col overflow-y-auto">
                {results.map((item) => (
                  <button
                    type="button"
                    key={item.id}
                    className={`hover:bg-blue cursor-pointer p-2.5 text-left ${item.id === addressId ? 'bg-blue hover:bg-' : ''}`}
                    onClick={() => setAddressId(item.id)}
                  >
                    {item.text}
                  </button>
                ))}
              </div>
            </div>
          )}
        </form>
        <div className="flex flex-col gap-4 sm:flex-row">
          <button
            className="text-dark-dark bg-light enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold"
            onClick={(e) => {
              e.preventDefault();
              setFormStage(FormStage.ADDRESS);
            }}
          >
            Set Manually
          </button>
          <button
            className={`text-dark-dark bg-blue enabled:hover:border-blue-dark border-blue enabled:hover:shadow-grey-sm h-14 w-full rounded-[20px] border-2 font-bold ${!addressId ? 'opacity-50' : ''}`}
            disabled={!addressId}
            onClick={(e) => {
              e.preventDefault();
              processSelectedAddress();
            }}
          >
            Continue To Property Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default PostcodeStage;
